import React, { useState, useEffect, useCallback } from 'react';
import { useChainContext } from '../../../contexts/ChainContext';
import { Database, Hash } from 'lucide-react';
import { getChainConfig } from '../../../config/chainConfig';

const SellRam = () => {
  const { currentChain, user } = useChainContext();
  const [ramInfo, setRamInfo] = useState({ total: 0, available: 0 });
  const [ramPrice, setRamPrice] = useState(0);
  const [bytes, setBytes] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [notification, setNotification] = useState(null);

  const chainConfig = getChainConfig(currentChain);
  const chainSymbol = chainConfig.symbol;

  const fetchRamInfoAndPrice = useCallback(async () => {
    if (user && user.accountName) {
      try {
        const accountInfo = await user.session.getAccount(user.accountName);
        setRamInfo({
          total: accountInfo.ram_quota,
          available: accountInfo.ram_quota - accountInfo.ram_usage
        });

        // Fetch RAM price (this is a simplified example, actual implementation may vary)
        const ramInfo = await user.session.getTable('eosio', 'eosio', 'rammarket');
        const ramData = ramInfo.rows[0];
        const ramPrice = parseFloat(ramData.quote.balance) / parseFloat(ramData.base.balance);
        setRamPrice(ramPrice);
      } catch (error) {
        console.error('Error fetching RAM info and price:', error);
        setNotification({ message: 'Failed to fetch account information', type: 'error' });
      }
    }
  }, [user]);

  useEffect(() => {
    fetchRamInfoAndPrice();
  }, [fetchRamInfoAndPrice]);

  const handleSellRam = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    setNotification(null);

    try {
      if (!user || !user.session) {
        throw new Error('User not authenticated');
      }

      const action = {
        account: 'eosio',
        name: 'sellram',
        authorization: [{ actor: user.accountName, permission: 'active' }],
        data: {
          account: user.accountName,
          bytes: parseInt(bytes)
        }
      };

      const result = await user.session.signTransaction(
        { actions: [action] },
        { broadcast: true, blocksBehind: 3, expireSeconds: 30 }
      );

      setNotification({ message: `Successfully sold ${bytes} bytes of RAM.`, type: 'success' });
      setBytes('');
      fetchRamInfoAndPrice(); // Refresh RAM info and price after successful transaction
    } catch (error) {
      console.error('Error selling RAM:', error);
      setNotification({ message: `Failed to sell RAM: ${error.message}`, type: 'error' });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-8xl mx-auto">
        <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Sell RAM</h1>
        <div className="bg-gradient-to-br from-purple-500 to-indigo-600 p-1 rounded-2xl shadow-lg">
          <div className="bg-white p-6 rounded-2xl">
            <form onSubmit={handleSellRam} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Available RAM</label>
                <input
                  type="text"
                  value={`${ramInfo.available.toLocaleString()} bytes`}
                  disabled
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-gray-100 text-gray-600"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">RAM Price</label>
                <input
                  type="text"
                  value={`${ramPrice.toFixed(8)} ${chainSymbol}/KB`}
                  disabled
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-gray-100 text-gray-600"
                />
              </div>
              <div>
                <label htmlFor="bytes" className="block text-sm font-medium text-gray-700 mb-1">Bytes to Sell</label>
                <div className="relative">
                  <input
                    id="bytes"
                    type="text"
                    value={bytes}
                    onChange={(e) => setBytes(e.target.value)}
                    className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    placeholder="Enter bytes"
                    required
                  />
                  <Hash className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                </div>
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="px-6 py-3 bg-gradient-to-r from-purple-500 to-indigo-600 text-white rounded-lg hover:from-purple-600 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex items-center disabled:opacity-50"
                  disabled={isProcessing}
                >
                  <Database className="w-5 h-5 mr-2" />
                  {isProcessing ? 'Processing...' : 'Sell RAM'}
                </button>
              </div>
            </form>
          </div>
        </div>

        {notification && (
          <div className={`mt-4 p-4 rounded-md ${notification.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
            {notification.message}
          </div>
        )}
      </div>
    </div>
  );
};

export default SellRam;