import React, { useState, useEffect } from 'react';
import { Database, Key, DollarSign, ChevronRight, ChevronDown } from 'lucide-react';
import { useChainContext } from '../contexts/ChainContext';
import AccountSummary from './AccountSummary';
import ResourcesSection from './ResourcesSection';

const AccountOverview = ({ accountInfo }) => {
  const { currentChain } = useChainContext();
  const [activeTab, setActiveTab] = useState('Tokens');
  const [visibleTokens, setVisibleTokens] = useState(3);
  const [keyTree, setKeyTree] = useState(null);

  useEffect(() => {
    if (accountInfo && accountInfo.account && accountInfo.account.permissions) {
      const tree = buildKeyTree(accountInfo.account.permissions);
      setKeyTree(tree);
    }
  }, [accountInfo]);

  const buildKeyTree = (permissions) => {
    const tree = {};
    const lookup = {};

    permissions.forEach(perm => {
      lookup[perm.perm_name] = { ...perm, children: [] };
    });

    permissions.forEach(perm => {
      if (perm.parent && lookup[perm.parent]) {
        lookup[perm.parent].children.push(lookup[perm.perm_name]);
      } else {
        tree[perm.perm_name] = lookup[perm.perm_name];
      }
    });

    return tree;
  };

  const renderKeyTree = (node, depth = 0) => {
    if (!node) return null;

    return (
      <div key={node.perm_name} style={{ marginLeft: `${depth * 20}px` }}>
        <div className="flex items-center space-x-2 mb-2">
          {node.children.length > 0 ? (
            <ChevronDown className="w-4 h-4 text-gray-500" />
          ) : (
            <ChevronRight className="w-4 h-4 text-gray-500" />
          )}
          <Key className="w-4 h-4 text-gray-500" />
          <span className="font-semibold">{node.perm_name}</span>
        </div>
        {node.required_auth.keys.map((key, index) => (
          <div key={index} className="flex items-center space-x-2 mb-2 ml-6">
            <span className="text-sm font-mono">{key.key}</span>
            <span className="text-xs bg-blue-100 text-blue-800 px-2 py-1 rounded">+{key.weight}</span>
          </div>
        ))}
        {node.children.map(child => renderKeyTree(child, depth + 1))}
      </div>
    );
  };

  const countTotalKeys = (tree) => {
    let count = 0;
    const countKeys = (node) => {
      if (!node) return;
      count += node.required_auth.keys.length;
      node.children.forEach(countKeys);
    };
    Object.values(tree).forEach(countKeys);
    return count;
  };

  const chainConfig = {
    'wax-mainnet': { token: 'WAX' },
    'wax-testnet': { token: 'WAX' },
    'telos-mainnet': { token: 'TLOS' },
    'telos-testnet': { token: 'TLOS' },
    // Add more chain configurations as needed
  };

  const chainToken = chainConfig[currentChain]?.token || 'TOKEN';

  const formatNumber = (num) => {
    if (typeof num === 'string') {
      const [value, symbol] = num.split(' ');
      return `${parseFloat(value).toLocaleString(undefined, { maximumFractionDigits: 8 })} ${symbol || chainToken}`;
    }
    return parseFloat(num).toLocaleString(undefined, { maximumFractionDigits: 8 });
  };

  const tokens = accountInfo.tokens || [];
  const totalTokens = tokens.length;

  const tabs = [
    { name: 'Tokens', count: totalTokens },
    { name: 'Keys', count: keyTree ? countTotalKeys(keyTree) : 0 },
    { name: 'Votes', label: 'Votes' },
  ];

  const loadMoreTokens = () => {
    setVisibleTokens(prev => Math.min(prev + 3, totalTokens));
  };

  const TokensTab = () => (
    <>
      <div>
        <p className="text-sm text-gray-600">Total Tokens: {totalTokens}</p>
        <p className="text-sm text-gray-600">Total Tokens Value: $0.00 USD / 0 {chainToken}</p>
      </div>
      <div className="grid grid-cols-3 gap-4">
        {tokens.slice(0, visibleTokens).map((token, index) => (
          <div key={index} className="flex items-center">
            <span className="w-6 h-6 bg-gray-200 rounded-full mr-2 flex items-center justify-center text-xs">
              <DollarSign size={14} />
            </span>
            <div>
              <p className="font-semibold">{token.contract}</p>
              <p>{formatNumber(token.amount)} {token.symbol}</p>
            </div>
          </div>
        ))}
      </div>
      {visibleTokens < totalTokens && (
        <div className="text-center mt-4">
          <button 
            onClick={loadMoreTokens}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Load more
          </button>
        </div>
      )}
    </>
  );

  const KeysTab = () => {
    if (!keyTree || Object.keys(keyTree).length === 0) {
      return <div className="text-center py-4">No key information available.</div>;
    }

    const totalKeys = countTotalKeys(keyTree);

    return (
      <div className="space-y-4">
        <div className="mb-4">
          <p className="text-sm text-gray-600">Total Keys: {totalKeys}</p>
        </div>
        {Object.values(keyTree).map(node => renderKeyTree(node))}
      </div>
    );
  };

  const VotesTab = () => {
    const voterInfo = accountInfo.account?.voter_info;
    
    if (!voterInfo) {
      return <div className="text-center py-4">No voting information available.</div>;
    }
  
    return (
      <div className="space-y-4">
        <h3 className="text-lg font-semibold">Voting Information</h3>
        {voterInfo.proxy ? (
          <div>
            <p className="text-sm text-gray-600">This account is voting for a proxy:</p>
            <p className="font-semibold">{voterInfo.proxy}</p>
          </div>
        ) : voterInfo.producers && voterInfo.producers.length > 0 ? (
          <div>
            <p className="text-sm text-gray-600 mb-2">This account is voting for the following producers:</p>
            <div className="grid grid-cols-3 gap-2">
              {voterInfo.producers.map((producer, index) => (
                <div key={index} className="bg-gray-100 rounded p-2 text-center">
                  <p className="text-xs font-medium text-gray-800 truncate">{producer}</p>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <p className="text-sm text-gray-600">This account is not currently voting for any producers or proxy.</p>
        )}
      </div>
    );
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Tokens':
        return <TokensTab />;
      case 'Keys':
        return <KeysTab />;
      case 'Votes':
        return <VotesTab />;
      default:
        return <div className="text-center py-4">Content for {activeTab} tab is not available yet.</div>;
    }
  };

  return (
    <div className="space-y-6">
      <AccountSummary accountInfo={accountInfo.account} />
      <ResourcesSection accountInfo={accountInfo.account} />
      
      <div className="bg-white rounded-lg shadow p-6">
        <div className="flex items-center mb-4">
          <Database className="w-6 h-6 mr-2 text-blue-500" />
          <h2 className="text-xl font-semibold">Chain Data</h2>
        </div>
        <div className="space-y-4">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8 overflow-x-auto">
              {tabs.map((tab) => (
                <button
                  key={tab.name}
                  onClick={() => setActiveTab(tab.name)}
                  className={`${
                    activeTab === tab.name
                      ? 'border-blue-500 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                >
                  {tab.label || tab.name.toUpperCase()}
                  {tab.count !== undefined && (
                    <span className={`ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium ${
                      activeTab === tab.name ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900'
                    }`}>
                      {tab.count}
                    </span>
                  )}
                </button>
              ))}
            </nav>
          </div>
          
          {renderTabContent()}
        </div>
      </div>
    </div>
  );
};

export default AccountOverview;