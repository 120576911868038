import React, { useState, useEffect, useMemo } from 'react';
import { useTable } from 'react-table';
import { useChainContext } from '../contexts/ChainContext';
import BlockchainService from '../services/blockchainService';
import { ArrowUpRight, ArrowDownRight, Lock, Database, Cpu } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import ReactCountryFlag from "react-country-flag";
import TokenFetcher from './TokenFetcher';  // Asegúrate de que la ruta sea correcta
import { Link } from 'react-router-dom';

function BlockchainInfoCard({ title, value, icon: Icon, change }) {
  return (
    <div className="bg-white rounded-lg shadow-md p-4 flex items-center justify-between">
      <div>
        <h3 className="text-sm font-medium text-gray-500">{title}</h3>
        <p className="mt-1 text-xl font-semibold text-gray-900">{value}</p>
        {change !== undefined && (
          <p className={`mt-1 text-sm ${change >= 0 ? 'text-green-600' : 'text-red-600'}`}>
            {change >= 0 ? <ArrowUpRight size={16} /> : <ArrowDownRight size={16} />}
            {Math.abs(change).toLocaleString()}
          </p>
        )}
      </div>
      <div className="bg-blue-100 rounded-full p-3">
        <Icon size={24} className="text-blue-600" />
      </div>
    </div>
  );
}

const fetchBpData = async (url) => {
  const cacheKey = `bp_data_${url}`;
  const cachedData = localStorage.getItem(cacheKey);
  
  if (cachedData) {
    return JSON.parse(cachedData);
  }

  try {
    const response = await fetch(url);
    const data = await response.json();
    const bpData = {
      logo: data.org?.branding?.logo_256,
      country: data.org?.location?.country
    };
    
    if (bpData.logo || bpData.country) {
      localStorage.setItem(cacheKey, JSON.stringify(bpData));
      return bpData;
    }
  } catch (error) {
    console.error('Error fetching BP data:', error);
  }
  
  return null;
};

function ProducerLogo({ url }) {
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    if (url) {
      const bpJsonUrl = url.endsWith('/') ? `${url}bp.json` : `${url}/bp.json`;
      fetchBpData(bpJsonUrl).then(data => setLogo(data?.logo));
    }
  }, [url]);

  if (!logo) {
    return null;
  }

  return (
    <img 
      src={logo} 
      alt="Producer Logo" 
      className="w-8 h-8 mr-2 object-contain"
      onError={(e) => { e.target.style.display = 'none' }}
    />
  );
}

function CountryFlag({ url }) {
  const [country, setCountry] = useState(null);

  useEffect(() => {
    if (url) {
      const bpJsonUrl = url.endsWith('/') ? `${url}bp.json` : `${url}/bp.json`;
      fetchBpData(bpJsonUrl).then(data => setCountry(data?.country));
    }
  }, [url]);

  if (!country) {
    return <span>N/A</span>;
  }

  return (
    <div className="flex items-center">
      <ReactCountryFlag countryCode={country} svg style={{ width: '1em', height: '1em' }} />
      <span className="ml-2">{country}</span>
    </div>
  );
}

function Monitor() {
  const { currentChain } = useChainContext();
  const [producers, setProducers] = useState([]);
  const [currentProducer, setCurrentProducer] = useState('');
  const [blockchainInfo, setBlockchainInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [blockchainService, setBlockchainService] = useState(() => new BlockchainService(currentChain));
  const [tokenData, setTokenData] = useState([]);

  useEffect(() => {
    setBlockchainService(new BlockchainService(currentChain));
  }, [currentChain]);

  useEffect(() => {
    if (!blockchainService) return;

    const fetchProducers = async () => {
      setLoading(true);
      try {
        const response = await blockchainService.fetchFromChain('/get_table_rows', {
          scope: 'eosio',
          code: 'eosio',
          table: 'producers',
          json: true,
          limit: 1000
        });
        const activeProducers = response.rows.filter(producer => producer.is_active);
        setProducers(activeProducers.sort((a, b) => parseFloat(b.total_votes) - parseFloat(a.total_votes)));
        setError(null);
      } catch (err) {
        setError('Failed to fetch producers data: ' + err.message);
        setProducers([]);
      } finally {
        setLoading(false);
      }
    };

    const fetchBlockchainInfo = async () => {
      try {
        const info = await blockchainService.getInfo();
        setCurrentProducer(info.head_block_producer);
        setBlockchainInfo(prevInfo => ({
          ...info,
          libChange: prevInfo ? info.last_irreversible_block_num - prevInfo.last_irreversible_block_num : 0
        }));
      } catch (err) {
        console.error('Failed to fetch blockchain info:', err.message);
      }
    };

    fetchProducers();
    fetchBlockchainInfo();

    const producersIntervalId = setInterval(fetchProducers, 60000);
    const blockchainInfoIntervalId = setInterval(fetchBlockchainInfo, 4000);

    return () => {
      clearInterval(producersIntervalId);
      clearInterval(blockchainInfoIntervalId);
    };
  }, [blockchainService]);

  useEffect(() => {
    const generateTokenData = () => {
      const data = [];
      let value = 1000;
      for (let i = 0; i < 30; i++) {
        value = value + Math.random() * 100 - 50;
        data.push({ name: `Day ${i + 1}`, value: Math.max(0, value) });
      }
      setTokenData(data);
    };

    generateTokenData();
    const intervalId = setInterval(generateTokenData, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const calculateVoteWeight = (votes, chainFamily) => {
    if (chainFamily === 'WAX') {
      const epoch = 946684800000.0;
      const now = Date.now();
      const firstcalc = (now / 1000) - (epoch / 1000);
      const floor = Math.floor(firstcalc / (86400 * 7)) / 13;
      const weight = Math.pow(2, floor);
      return votes / weight / 100000000;
    } else if (chainFamily === 'TELOS') {
      return votes / 10000;
    }
    return votes; // Default case
  };

  const columns = useMemo(
    () => [
      {
        Header: '#',
        id: 'row',
        Cell: ({ row }) => row.index + 1,
        disableSortBy: true,
      },
      {
        Header: 'Producer',
        accessor: 'owner',
        Cell: ({ row }) => (
          <div className="flex items-center">
            <ProducerLogo url={row.original.url} />
            <span>{row.original.owner}</span>
          </div>
        ),
      },
      {
        Header: 'Country',
        accessor: 'url',
        Cell: ({ value }) => <CountryFlag url={value} />,
        disableSortBy: true,
      },
      {
        Header: 'Total Votes',
        accessor: 'total_votes',
        Cell: ({ value }) => {
          const calculatedVotes = calculateVoteWeight(parseFloat(value), blockchainService?.getChainFamily());
          return calculatedVotes.toLocaleString(undefined, { maximumFractionDigits: 0 });
        },
      }
    ],
    [blockchainService]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    { 
      columns, 
      data: producers
    }
  );

  if (loading && producers.length === 0) return <div className="text-center p-4">Loading blockchain data...</div>;
  if (error) return <div className="text-center p-4 text-red-500">Error: {error}</div>;

  return (
    <div className="container mx-auto px-4">
            
      {blockchainInfo && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
          <BlockchainInfoCard
            title="Head Block"
            value={blockchainInfo.head_block_num.toLocaleString()}
            icon={Database}
          />
          <BlockchainInfoCard
            title="Last Irreversible Block"
            value={blockchainInfo.last_irreversible_block_num.toLocaleString()}
            icon={Lock}
          />
          <BlockchainInfoCard
            title="Current Producer"
            value={currentProducer}
            icon={Cpu}
          />
          <BlockchainInfoCard
            title="Server Version"
            value={blockchainInfo.server_version}
            icon={ArrowUpRight}
          />
        </div>
      )}

      <div className="bg-white shadow-md rounded p-4 mb-6">
        <h2 className="text-xl font-semibold mb-4">Token Price ({blockchainService?.getChainSymbol()})</h2>
        <div className="h-[200px]">
          <TokenFetcher />
        </div>
      </div>

      <div className="overflow-x-auto bg-white shadow-md rounded">
        <table {...getTableProps()} className="min-w-full">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps()}
                    className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row)
              return (
                <tr 
                  {...row.getRowProps()} 
                  className={`hover:bg-gray-50 ${row.original.owner === currentProducer ? 'bg-green-100' : ''}`}
                >
                  {row.cells.map(cell => (
                    <td
                      {...cell.getCellProps()}
                      className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500"
                    >
                      {cell.column.id === 'row' ? (
                        index + 1
                      ) : cell.column.id === 'owner' ? (
                        <div className="flex items-center">
                          <ProducerLogo url={row.original.url} />
                          <Link 
                            to={`/account-detail/${row.original.owner}`} 
                            className="text-blue-600 hover:text-blue-800 hover:underline"
                          >
                            {row.original.owner}
                          </Link>
                        </div>
                      ) : (
                        cell.render('Cell')
                      )}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Monitor;