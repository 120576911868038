import React from 'react';

function Footer() {
  return (
    <footer className="bg-white">
      <div className="container mx-auto px-6 py-4">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-gray-700 text-sm">© 2024 Multi-Chain Blockchain Explorer and Wallet</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;