import React, { useState, useEffect } from 'react';
import { Cpu, HardDrive, RefreshCw, Plus, Minus } from 'lucide-react';
import AddCpuNet from './resources/AddCpuNet';
import RemoveCpuNet from './resources/RemoveCpuNet';
import RefundCpuNet from './resources/RefundCpuNet';
import BuyRam from './resources/BuyRam';
import SellRam from './resources/SellRam';
import BlockchainService from '../../services/blockchainService';
import { useChainContext } from '../../contexts/ChainContext';
import AccountSummary from '../../components/AccountSummary';
import ResourcesSection from '../../components/ResourcesSection';


const ActionButton = ({ icon: Icon, label, onClick, isActive }) => (
  <button
    onClick={onClick}
    className={`flex items-center justify-center px-4 py-2 border ${
      isActive
        ? 'bg-indigo-600 text-white'
        : 'bg-white text-gray-700 hover:bg-gray-50'
    } rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
  >
    <Icon className="w-5 h-5 mr-2" />
    {label}
  </button>
);

const Resources = () => {
  const { user, currentChain } = useChainContext();
  const [activeAction, setActiveAction] = useState('add');
  const [userAccountInfo, setUserAccountInfo] = useState(null);

  useEffect(() => {
    const fetchAccountInfo = async () => {
      try {
        if (user && user.name) {

          console.log("obteniendo info de la cuenta", user.name);

          const blockchainService = new BlockchainService(currentChain);
          const info = await blockchainService.fetchFromChain(`/get_account/${user.name}`);
          console.log("info de la cuenta", info);
          setUserAccountInfo(info);
        } else {
          setUserAccountInfo(null);
        }
      } catch (err) {
        console.error('Error fetching user account info:', err);
      }
    };

    fetchAccountInfo();
  }, [user, currentChain]);

  const renderActionContent = () => {
    switch (activeAction) {
      case 'add':
        return <AddCpuNet />;
      case 'remove':
        return <RemoveCpuNet />;
      case 'refund':
        return <RefundCpuNet />;
      case 'buyRam':
        return <BuyRam />;
      case 'sellRam':
        return <SellRam />;
      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-7xl">
        <div className="max-w-6xl mx-auto">
      

      {user && userAccountInfo && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <AccountSummary accountInfo={userAccountInfo.account} />
          <ResourcesSection accountInfo={userAccountInfo.account} />
        </div>
      )}
      
      <div className="flex flex-wrap justify-center space-x-2 space-y-2 mb-6">
        <ActionButton
          icon={Plus}
          label="Add CPU/NET"
          onClick={() => setActiveAction('add')}
          isActive={activeAction === 'add'}
        />
        <ActionButton
          icon={Minus}
          label="Remove CPU/NET"
          onClick={() => setActiveAction('remove')}
          isActive={activeAction === 'remove'}
        />
        <ActionButton
          icon={RefreshCw}
          label="Refund CPU/NET"
          onClick={() => setActiveAction('refund')}
          isActive={activeAction === 'refund'}
        />
        <ActionButton
          icon={Plus}
          label="Buy RAM"
          onClick={() => setActiveAction('buyRam')}
          isActive={activeAction === 'buyRam'}
        />
        <ActionButton
          icon={Minus}
          label="Sell RAM"
          onClick={() => setActiveAction('sellRam')}
          isActive={activeAction === 'sellRam'}
        />
      </div>


      {renderActionContent()}

    </div>
    </div>
  );
};

export default Resources;
