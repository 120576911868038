import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useChainContext } from '../contexts/ChainContext';
import BlockchainService from '../services/blockchainService';
import AccountOverview from '../components/AcountOverview';
import AccountActions from '../components/AccountActions';

function AccountDetail() {
  const { accountName } = useParams();
  const { currentChain } = useChainContext();
  const [accountInfo, setAccountInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAccountInfo = async () => {
      setLoading(true);
      setError(null);
      try {
        const blockchainService = new BlockchainService(currentChain);
        const info = await blockchainService.fetchFromChain(`/get_account/${accountName}`);
        console.log(info);
        setAccountInfo(info);
      } catch (err) {
        setError(`Error fetching account info: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchAccountInfo();
  }, [accountName, currentChain]);

  if (loading) return <div>Loading account information...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto px-4 py-8 space-y-8"> {/* Added space-y-8 for vertical spacing */}
      {accountInfo && (
        <div className="bg-white p-6 rounded-lg shadow-md">
          <AccountOverview accountInfo={accountInfo} />
        </div>
      )}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <AccountActions accountName={accountName} />
      </div>
    </div>
  );
}

export default AccountDetail;