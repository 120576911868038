import React, { useState, useEffect, useCallback } from 'react';
import { useChainContext } from '../../../contexts/ChainContext';
import { RefreshCw } from 'lucide-react';
import { getChainConfig } from '../../../config/chainConfig';

const RefundCpuNet = () => {
  const { currentChain, user } = useChainContext();
  const [refundInfo, setRefundInfo] = useState({ cpu: 0, net: 0, time: null });
  const [isProcessing, setIsProcessing] = useState(false);
  const [notification, setNotification] = useState(null);

  const chainConfig = getChainConfig(currentChain);
  const chainSymbol = chainConfig.symbol;

  const fetchRefundInfo = useCallback(async () => {
    if (user && user.accountName) {
      try {
        const accountInfo = await user.session.getAccount(user.accountName);
        if (accountInfo.refund_request) {
          setRefundInfo({
            cpu: parseFloat(accountInfo.refund_request.cpu_amount.split(' ')[0]),
            net: parseFloat(accountInfo.refund_request.net_amount.split(' ')[0]),
            time: new Date(accountInfo.refund_request.request_time + 'Z')
          });
        } else {
          setRefundInfo({ cpu: 0, net: 0, time: null });
        }
      } catch (error) {
        console.error('Error fetching refund info:', error);
        setNotification({ message: 'Failed to fetch refund information', type: 'error' });
      }
    }
  }, [user]);

  useEffect(() => {
    fetchRefundInfo();
  }, [fetchRefundInfo]);

  const handleRefund = async () => {
    setIsProcessing(true);
    setNotification(null);

    try {
      if (!user || !user.session) {
        throw new Error('User not authenticated');
      }

      const action = {
        account: 'eosio',
        name: 'refund',
        authorization: [{ actor: user.accountName, permission: 'active' }],
        data: {
          owner: user.accountName
        }
      };

      const result = await user.session.signTransaction(
        { actions: [action] },
        { broadcast: true, blocksBehind: 3, expireSeconds: 30 }
      );

      setNotification({ message: 'Refund successfully processed.', type: 'success' });
      fetchRefundInfo(); // Refresh refund info after successful transaction
    } catch (error) {
      console.error('Error processing refund:', error);
      setNotification({ message: `Failed to process refund: ${error.message}`, type: 'error' });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-8xl mx-auto">
        <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Refund CPU/NET</h1>
        <div className="bg-gradient-to-br from-purple-500 to-indigo-600 p-1 rounded-2xl shadow-lg">
          <div className="bg-white p-6 rounded-2xl">
            <div className="space-y-6">
              <div>
                <h2 className="text-xl font-semibold mb-2">Pending Refund</h2>
                <p>CPU: {refundInfo.cpu.toFixed(4)} {chainSymbol}</p>
                <p>NET: {refundInfo.net.toFixed(4)} {chainSymbol}</p>
                {refundInfo.time && (
                  <p>Available on: {refundInfo.time.toLocaleString()}</p>
                )}
              </div>
              <div className="flex items-center justify-between">
                <button
                  onClick={handleRefund}
                  className="px-6 py-3 bg-gradient-to-r from-purple-500 to-indigo-600 text-white rounded-lg hover:from-purple-600 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex items-center disabled:opacity-50"
                  disabled={isProcessing || (refundInfo.cpu === 0 && refundInfo.net === 0)}
                >
                  <RefreshCw className="w-5 h-5 mr-2" />
                  {isProcessing ? 'Processing...' : 'Process Refund'}
                </button>
              </div>
            </div>
          </div>
        </div>

        {notification && (
          <div className={`mt-4 p-4 rounded-md ${notification.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
            {notification.message}
          </div>
        )}
      </div>
    </div>
  );
};

export default RefundCpuNet;