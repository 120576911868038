import React, { useState, useRef, useEffect } from 'react';
import { useChainContext } from '../contexts/ChainContext';
import chainConfig from '../config/chainConfig';
import WalletConnector from './WalletConnector';
import AccountSearch from './AccountSearch';
import { Menu, ChevronDown, User } from 'lucide-react';

function Header({ setSidebarOpen }) {
  const { currentChain, setCurrentChain, user, isAuthenticated } = useChainContext();
  const [isNetworkDropdownOpen, setIsNetworkDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsNetworkDropdownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const currentNetworkName = chainConfig[currentChain]?.name || 'Select Network';

  const handleChainChange = (chainId) => {
    if (isAuthenticated) {
      const confirmChange = window.confirm("Changing the network will log you out. Are you sure you want to continue?");
      if (confirmChange) {
        setCurrentChain(chainId);
      }
    } else {
      setCurrentChain(chainId);
    }
    setIsNetworkDropdownOpen(false);
  };

  return (
    <header className="bg-gradient-to-r from-purple-600 to-indigo-600 text-white shadow-lg">
      <div className="container mx-auto px-4 py-3">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4 flex-grow">
            <button
              className="text-white focus:outline-none lg:hidden"
              onClick={() => setSidebarOpen(true)}
              aria-label="Open sidebar"
            >
              <Menu size={24} />
            </button>
            <AccountSearch />
          </div>
          
          <div className="flex items-center space-x-4">
            <div className="relative" ref={dropdownRef}>
              <button
                className="flex items-center justify-between w-48 px-4 py-2 text-sm font-medium text-white bg-opacity-20 bg-white border border-white border-opacity-30 rounded-md shadow-sm hover:bg-opacity-30 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white"
                onClick={() => setIsNetworkDropdownOpen(!isNetworkDropdownOpen)}
                aria-haspopup="true"
                aria-expanded={isNetworkDropdownOpen}
              >
                <span>{currentNetworkName}</span>
                <ChevronDown size={16} />
              </button>

              {isNetworkDropdownOpen && (
                <div className="absolute right-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                  <div className="py-1 grid grid-cols-2 gap-2" role="menu" aria-orientation="vertical">
                    {Object.entries(chainConfig).map(([chainId, chain]) => (
                      <button
                        key={chainId}
                        className={`${
                          chainId === currentChain ? 'bg-indigo-100 text-indigo-900' : 'text-gray-700'
                        } block px-4 py-2 text-sm hover:bg-indigo-50 w-full text-left`}
                        onClick={() => handleChainChange(chainId)}
                        role="menuitem"
                      >
                        {chain.name}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <WalletConnector />

            {isAuthenticated && user && (
              <div className="flex items-center space-x-2">
                <User size={20} />
                <span className="text-sm font-medium hidden md:inline">
                  {user.name}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;