import React, { useState, useEffect, useCallback } from 'react';
import { useChainContext } from '../../../contexts/ChainContext';
import { Database, DollarSign } from 'lucide-react';
import { getChainConfig } from '../../../config/chainConfig';

const BuyRam = () => {
  const { currentChain, user } = useChainContext();
  const [balance, setBalance] = useState(0);
  const [ramPrice, setRamPrice] = useState(0);
  const [quantity, setQuantity] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [notification, setNotification] = useState(null);

  const chainConfig = getChainConfig(currentChain);
  const chainSymbol = chainConfig.symbol;

  const fetchBalanceAndRamPrice = useCallback(async () => {
    if (user && user.accountName) {
      try {
        const balance = await user.session.getBalance(user.accountName, chainSymbol);
        setBalance(parseFloat(balance));

        // Fetch RAM price (this is a simplified example, actual implementation may vary)
        const ramInfo = await user.session.getTable('eosio', 'eosio', 'rammarket');
        const ramData = ramInfo.rows[0];
        const ramPrice = parseFloat(ramData.quote.balance) / parseFloat(ramData.base.balance);
        setRamPrice(ramPrice);
      } catch (error) {
        console.error('Error fetching balance and RAM price:', error);
        setNotification({ message: 'Failed to fetch account information', type: 'error' });
      }
    }
  }, [user, chainSymbol]);

  useEffect(() => {
    fetchBalanceAndRamPrice();
  }, [fetchBalanceAndRamPrice]);

    const handleBuyRam = async (e) => {
        e.preventDefault();
        setIsProcessing(true);
        setNotification(null);
    
        try {
          if (!user || !user.session) {
            throw new Error('User not authenticated');
          }
    
          const action = {
            account: 'eosio',
            name: 'buyram',
            authorization: [{ actor: user.accountName, permission: 'active' }],
            data: {
              payer: user.accountName,
              receiver: user.accountName,
              quant: `${parseFloat(quantity).toFixed(4)} ${chainSymbol}`
            }
          };
    
          const result = await user.session.signTransaction(
            { actions: [action] },
            { broadcast: true, blocksBehind: 3, expireSeconds: 30 }
          );
    
          setNotification({ message: `Successfully bought RAM for ${quantity} ${chainSymbol}.`, type: 'success' });
          setQuantity('');
          fetchBalanceAndRamPrice(); // Refresh balance and RAM price after successful transaction
        } catch (error) {
          console.error('Error buying RAM:', error);
          setNotification({ message: `Failed to buy RAM: ${error.message}`, type: 'error' });
        } finally {
          setIsProcessing(false);
        }
      };
    
      return (
        <div className="container mx-auto px-4 py-8">
          <div className="max-w-8xl mx-auto">
            <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Buy RAM</h1>
            <div className="bg-gradient-to-br from-purple-500 to-indigo-600 p-1 rounded-2xl shadow-lg">
              <div className="bg-white p-6 rounded-2xl">
                <form onSubmit={handleBuyRam} className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Available Balance</label>
                    <input
                      type="text"
                      value={`${balance.toFixed(4)} ${chainSymbol}`}
                      disabled
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-gray-100 text-gray-600"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">RAM Price</label>
                    <input
                      type="text"
                      value={`${ramPrice.toFixed(8)} ${chainSymbol}/KB`}
                      disabled
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-gray-100 text-gray-600"
                    />
                  </div>
                  <div>
                    <label htmlFor="quantity" className="block text-sm font-medium text-gray-700 mb-1">Quantity to Buy</label>
                    <div className="relative">
                      <input
                        id="quantity"
                        type="text"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                        placeholder={`0.0000 ${chainSymbol}`}
                        required
                      />
                      <DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <button
                      type="submit"
                      className="px-6 py-3 bg-gradient-to-r from-purple-500 to-indigo-600 text-white rounded-lg hover:from-purple-600 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex items-center disabled:opacity-50"
                      disabled={isProcessing}
                    >
                      <Database className="w-5 h-5 mr-2" />
                      {isProcessing ? 'Processing...' : 'Buy RAM'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
    
            {notification && (
              <div className={`mt-4 p-4 rounded-md ${notification.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
                {notification.message}
              </div>
            )}
          </div>
        </div>
      );
    };
    
    export default BuyRam;