import React, { useState, useEffect } from 'react';
import { useChainContext } from '../contexts/ChainContext';
import BlockchainService from '../services/blockchainService';
import { ChevronLeft, ChevronRight, ArrowUpRight, ArrowDownRight } from 'lucide-react';
import ActionRenderer from './ActionRenderer';

const AccountActions = ({ accountName }) => {
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentChain } = useChainContext();
  const [filters, setFilters] = useState({
    after: '',
    before: '',
    category: '',
    contract: '',
    action: '',
    token: 'All Tokens'
  });
  const [totalActions, setTotalActions] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [goToPage, setGoToPage] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(20);

  
  useEffect(() => {
    const fetchActions = async () => {
      if (!accountName) {
        setError('Account name is undefined');
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        const blockchainService = new BlockchainService(currentChain);
        const queryParams = new URLSearchParams({
          limit: itemsPerPage.toString(),
          skip: ((currentPage - 1) * itemsPerPage).toString(),
          account: accountName,
          after: filters.after,
          before: filters.before,
          simple: 'true',
          checkLib: 'true'
        }).toString();
        
        console.log(`Fetching actions for account: ${accountName}`);
        const response = await blockchainService.fetchFromChain(`/get_actions?${queryParams}`);
        console.log('Response:', response);
        setActions(response.simple_actions || []);
        setTotalActions(response.total?.value || 0);
        setError(null);
      } catch (err) {
        console.error('Error fetching account actions:', err);
        setError('Failed to fetch account actions: ' + err.message);
        setActions([]);
      } finally {
        setLoading(false);
      }
    };

    fetchActions();
  }, [accountName, currentChain, filters, currentPage, itemsPerPage]);

  const handleFilterChange = (filterType, value) => {
    setFilters(prevFilters => ({ ...prevFilters, [filterType]: value }));
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleGoToPage = (e) => {
    e.preventDefault();
    const pageNumber = parseInt(goToPage);
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setGoToPage('');
    }
  };

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    setCurrentPage(1);
  };

  const filteredActions = actions.filter(action => {
    return (
      (filters.category === '' || action.contract === filters.category) &&
      (filters.contract === '' || action.contract === filters.contract) &&
      (filters.action === '' || action.action === filters.action) &&
      (filters.token === 'All Tokens' || (action.data && action.data.symbol === filters.token))
    );
  });

  const totalPages = Math.ceil(totalActions / itemsPerPage);

  const getPaginationGroup = () => {
    let start = Math.max(currentPage - 2, 1);
    let end = Math.min(start + 4, totalPages);
    
    if (end === totalPages) {
      start = Math.max(end - 4, 1);
    }

    return Array.from({length: end - start + 1}, (_, i) => start + i);
  };

  const renderActionData = (action) => {
    if (action.action === 'transfer' && action.contract === 'eosio.token') {
      return (
        <div className="flex items-center space-x-2">
          <span className="font-medium">{action.data.from}</span>
          {action.data.from === accountName ? (
            <ArrowUpRight className="text-red-500" />
          ) : (
            <ArrowDownRight className="text-green-500" />
          )}
          <span className="font-medium">{action.data.to}</span>
          <span className="font-bold">{action.data.quantity}</span>
          {action.data.memo && (
            <span className="text-gray-500 italic">"{action.data.memo}"</span>
          )}
        </div>
      );
    }
    return <pre className="whitespace-pre-wrap text-sm">{JSON.stringify(action.data, null, 2)}</pre>;
  };

  if (loading) return <div className="text-center py-8">Loading account actions...</div>;
  if (error) return <div className="text-center py-8 text-red-500">Error: {error}</div>;

  return (
    <div className="space-y-6">
      
      
      {/* Filters */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold mb-4">Filters</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <input
            type="datetime-local"
            placeholder="After"
            value={filters.after}
            onChange={(e) => handleFilterChange('after', e.target.value)}
            className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
          />
          <input
            type="datetime-local"
            placeholder="Before"
            value={filters.before}
            onChange={(e) => handleFilterChange('before', e.target.value)}
            className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
          />
          <select
            value={filters.category}
            onChange={(e) => handleFilterChange('category', e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
          >
            <option value="">All Categories</option>
            <option value="eosio.token">Token</option>
            <option value="eosio">System</option>
            <option value="eosio.msig">Multisig</option>
          </select>
          <input
            type="text"
            placeholder="Action name"
            value={filters.action}
            onChange={(e) => handleFilterChange('action', e.target.value)}
            className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
          />
        </div>
      </div>

      {/* Items per page selector */}
      <div className="flex justify-end">
        <select
          value={itemsPerPage}
          onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}
          className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
        >
          <option value={20}>20 per page</option>
          <option value={50}>50 per page</option>
          <option value={100}>100 per page</option>
          
        </select>
      </div>

      {/* Actions Table */}
      {filteredActions.length > 0 ? (
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">TX</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Data</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredActions.map((action, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{action.transaction_id.substring(0, 8)}...</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{new Date(action.timestamp).toLocaleString()}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{`${action.contract}::${action.action}`}</td>
                  
                  <ActionRenderer action={action} accountName={accountName} />
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center py-4">No actions found matching the current filters.</p>
      )}

      {/* Pagination */}
      <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{((currentPage - 1) * itemsPerPage) + 1}</span> to <span className="font-medium">{Math.min(currentPage * itemsPerPage, totalActions)}</span> of{' '}
              <span className="font-medium">{totalActions}</span> results
            </p>
          </div>
          <div className="flex items-center space-x-2">
            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeft className="h-5 w-5" aria-hidden="true" />
              </button>
              {getPaginationGroup().map((page) => (
                <button
                  key={page}
                  onClick={() => handlePageChange(page)}
                  className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                    page === currentPage
                      ? 'z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                      : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                  }`}
                >
                  {page}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              >
                <span className="sr-only">Next</span>
                <ChevronRight className="h-5 w-5" aria-hidden="true" />
              </button>
            </nav>
            <form onSubmit={handleGoToPage} className="flex items-center space-x-2">
              <input
                type="number"
                min="1"
                max={totalPages}
                value={goToPage}
                onChange={(e) => setGoToPage(e.target.value)}
                className="w-16 px-2 py-1 text-sm border rounded"
                placeholder="Page"
              />
              <button
                type="submit"
                className="px-2 py-1 text-sm bg-indigo-600 text-white rounded hover:bg-indigo-700"
              >
                Go
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountActions;