import React, { useState, useEffect, useMemo } from 'react';
import { useChainContext } from '../contexts/ChainContext';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceArea } from 'recharts';

const TokenPrices = () => {
  const [prices, setPrices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentChain } = useChainContext();
  const [left, setLeft] = useState('dataMin');
  const [right, setRight] = useState('dataMax');
  const [refAreaLeft, setRefAreaLeft] = useState('');
  const [refAreaRight, setRefAreaRight] = useState('');

  useEffect(() => {
    const fetchPrices = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`http://localhost:5000/api/${currentChain}/token_prices`);
        const formattedData = response.data.map(item => ({
          date: new Date(item.date).toLocaleDateString(),
          price: parseFloat(item.price)
        }));
        setPrices(formattedData);
        setError(null);
      } catch (err) {
        console.error('Error fetching token prices:', err);
        setError('Failed to fetch token prices. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchPrices();
  }, [currentChain]);

  const maxPrice = useMemo(() => {
    return prices.length > 0 ? Math.max(...prices.map(item => item.price)) : 0;
  }, [prices]);

  const yAxisDomain = useMemo(() => {
    return [0, maxPrice * 1.25];
  }, [maxPrice]);

  const zoomIn = () => {
    if (refAreaLeft === refAreaRight || refAreaRight === '') {
      setRefAreaLeft('');
      setRefAreaRight('');
      return;
    }

    let [leftStr, rightStr] = [refAreaLeft, refAreaRight].sort((a, b) => a.localeCompare(b));
    setLeft(leftStr);
    setRight(rightStr);
    setRefAreaLeft('');
    setRefAreaRight('');
  };

  const zoomOut = () => {
    setLeft('dataMin');
    setRight('dataMax');
    setRefAreaLeft('');
    setRefAreaRight('');
  };

  if (loading) return <div>Loading token prices...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
     
      <ResponsiveContainer width="100%" height={200}>
        <LineChart
          data={prices}
          onMouseDown={e => e && setRefAreaLeft(e.activeLabel)}
          onMouseMove={e => e && refAreaLeft && setRefAreaRight(e.activeLabel)}
          onMouseUp={zoomIn}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="date" 
            allowDataOverflow={true}
            domain={[left, right]}
          />
          <YAxis 
            allowDataOverflow={true}
            domain={yAxisDomain}
            type="number"
            tickFormatter={value => `$${value.toFixed(4)}`}
          />
          <Tooltip 
            formatter={(value) => [`$${value.toFixed(4)}`, 'Price']}
          />
          <Line type="monotone" dataKey="price" stroke="#8884d8" dot={false} />
          {refAreaLeft && refAreaRight ? (
            <ReferenceArea x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
          ) : null}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TokenPrices;