import React, { createContext, useContext, useState, useCallback } from 'react';

const ChainContext = createContext();

export const useChainContext = () => useContext(ChainContext);

export const ChainProvider = ({ children }) => {
  const [currentChain, setCurrentChain] = useState('wax-mainnet');
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const login = useCallback((userData) => {
    setUser(userData);
    setIsAuthenticated(true);
  }, []);

  const logout = useCallback(() => {
    setUser(null);
    setIsAuthenticated(false);
  }, []);

  const changeChain = useCallback((newChain) => {
    if (isAuthenticated) {
      // Logout user when changing chains
      logout();
    }
    setCurrentChain(newChain);
  }, [isAuthenticated, logout]);

  return (
    <ChainContext.Provider value={{ 
      currentChain, 
      setCurrentChain: changeChain, 
      user, 
      isAuthenticated, 
      login, 
      logout 
    }}>
      {children}
    </ChainContext.Provider>
  );
};