import React from 'react';
import { Wallet } from 'lucide-react';
import { useChainContext } from '../contexts/ChainContext';

const ResourcesSection = ({ accountInfo }) => {
  const { currentChain } = useChainContext();

  const chainConfig = {
    'wax-mainnet': { token: 'WAX' },
    'wax-testnet': { token: 'WAX' },
    'telos-mainnet': { token: 'TLOS' },
    'telos-testnet': { token: 'TLOS' },
    // Add more chain configurations as needed
  };

  const chainToken = chainConfig[currentChain]?.token || 'TOKEN';

  const formatNumber = (num) => {
    if (typeof num === 'string') {
      const [value, symbol] = num.split(' ');
      return `${parseFloat(value).toLocaleString(undefined, { maximumFractionDigits: 8 })} ${symbol || chainToken}`;
    }
    return parseFloat(num).toLocaleString(undefined, { maximumFractionDigits: 8 });
  };

  const totalStaked = parseFloat(accountInfo.voter_info?.staked) / 10000 || 0;
  const selfStakedCPU = parseFloat(accountInfo.self_delegated_bandwidth?.cpu_weight.split(' ')[0]) || 0;
  const selfStakedNET = parseFloat(accountInfo.self_delegated_bandwidth?.net_weight.split(' ')[0]) || 0;
  const selfStakedTotal = selfStakedCPU + selfStakedNET;

  return (
    <div className="bg-white rounded-lg shadow p-6 mb-6">
      <div className="flex items-center mb-4">
        <Wallet className="w-6 h-6 mr-2 text-green-500" />
        <h2 className="text-xl font-semibold">Resources</h2>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <p className="text-sm text-gray-600">Available:</p>
          <p className="font-semibold">{formatNumber(accountInfo.core_liquid_balance)}</p>
        </div>
        <div>
          <p className="text-sm text-gray-600">RAM:</p>
          <div className="w-full bg-gray-200 rounded-full h-2.5">
            <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${(accountInfo.ram_usage / accountInfo.ram_quota) * 100}%` }}></div>
          </div>
        </div>
        <div>
          <p className="text-sm text-gray-600">Refund:</p>
          <p className="font-semibold">{formatNumber(accountInfo.refund_request?.net_amount || `0 ${chainToken}`)}</p>
        </div>
        <div>
          <p className="text-sm text-gray-600">CPU:</p>
          <div className="w-full bg-gray-200 rounded-full h-2.5">
            <div className="bg-green-600 h-2.5 rounded-full" style={{ width: `${(accountInfo.cpu_limit?.used / accountInfo.cpu_limit?.max) * 100}%` }}></div>
          </div>
        </div>
        <div>
          <p className="text-sm text-gray-600">CPU Staked:</p>
          <p className="font-semibold">{formatNumber(accountInfo.self_delegated_bandwidth?.cpu_weight || `0 ${chainToken}`)}</p>
        </div>
        <div>
          <p className="text-sm text-gray-600">NET:</p>
          <div className="w-full bg-gray-200 rounded-full h-2.5">
            <div className="bg-purple-600 h-2.5 rounded-full" style={{ width: `${(accountInfo.net_limit?.used / accountInfo.net_limit?.max) * 100}%` }}></div>
          </div>
        </div>
        <div>
          <p className="text-sm text-gray-600">NET Staked:</p>
          <p className="font-semibold">{formatNumber(accountInfo.self_delegated_bandwidth?.net_weight || `0 ${chainToken}`)}</p>
        </div>
      </div>
    </div>
  );
};

export default ResourcesSection;