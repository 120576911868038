import React, { useState, useEffect, useCallback } from 'react';
import { useChainContext } from '../../../contexts/ChainContext';
import { Cpu, HardDrive, User, DollarSign } from 'lucide-react';
import { getChainConfig } from '../../../config/chainConfig';

const RemoveCpuNet = () => {
  const { currentChain, user } = useChainContext();
  const [resources, setResources] = useState({ cpu: 0, net: 0 });
  const [resourceType, setResourceType] = useState('cpu');
  const [quantity, setQuantity] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [notification, setNotification] = useState(null);

  const chainConfig = getChainConfig(currentChain);
  const chainSymbol = chainConfig.symbol;

  const fetchResources = useCallback(async () => {
    if (user && user.accountName) {
      try {
        const accountInfo = await user.session.getAccount(user.accountName);
        setResources({
          cpu: parseFloat(accountInfo.cpu_weight.split(' ')[0]),
          net: parseFloat(accountInfo.net_weight.split(' ')[0])
        });
      } catch (error) {
        console.error('Error fetching resources:', error);
        setNotification({ message: 'Failed to fetch account resources', type: 'error' });
      }
    }
  }, [user]);

  useEffect(() => {
    fetchResources();
  }, [fetchResources]);

  const handleRemoveResource = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    setNotification(null);

    try {
      if (!user || !user.session) {
        throw new Error('User not authenticated');
      }

      const action = {
        account: 'eosio',
        name: 'undelegatebw',
        authorization: [{ actor: user.accountName, permission: 'active' }],
        data: {
          from: user.accountName,
          receiver: user.accountName,
          unstake_net_quantity: resourceType === 'net' ? `${quantity} ${chainSymbol}` : `0.0000 ${chainSymbol}`,
          unstake_cpu_quantity: resourceType === 'cpu' ? `${quantity} ${chainSymbol}` : `0.0000 ${chainSymbol}`
        }
      };

      const result = await user.session.signTransaction(
        { actions: [action] },
        { broadcast: true, blocksBehind: 3, expireSeconds: 30 }
      );

      setNotification({ message: `Successfully removed ${quantity} ${chainSymbol} from ${resourceType.toUpperCase()}.`, type: 'success' });
      setQuantity('');
      fetchResources(); // Refresh resources after successful transaction
    } catch (error) {
      console.error('Error removing resource:', error);
      setNotification({ message: `Failed to remove ${resourceType.toUpperCase()}: ${error.message}`, type: 'error' });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-8xl mx-auto">
        <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Remove CPU/NET</h1>
        <div className="bg-gradient-to-br from-purple-500 to-indigo-600 p-1 rounded-2xl shadow-lg">
          <div className="bg-white p-6 rounded-2xl">
            <form onSubmit={handleRemoveResource} className="space-y-6">
              <div className="grid grid-cols-1 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Staked Resources</label>
                  <div className="grid grid-cols-2 gap-4">
                    <input
                      type="text"
                      value={`${resources.cpu.toFixed(4)} ${chainSymbol} (CPU)`}
                      disabled
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-gray-100 text-gray-600"
                    />
                    <input
                      type="text"
                      value={`${resources.net.toFixed(4)} ${chainSymbol} (NET)`}
                      disabled
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-gray-100 text-gray-600"
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Resource Type</label>
                  <div className="flex space-x-4">
                    <button
                      type="button"
                      onClick={() => setResourceType('cpu')}
                      className={`flex-1 py-2 px-4 rounded-md flex items-center justify-center ${
                        resourceType === 'cpu' ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-700'
                      }`}
                    >
                      <Cpu className="w-5 h-5 mr-2" />
                      CPU
                    </button>
                    <button
                      type="button"
                      onClick={() => setResourceType('net')}
                      className={`flex-1 py-2 px-4 rounded-md flex items-center justify-center ${
                        resourceType === 'net' ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-700'
                      }`}
                    >
                      <HardDrive className="w-5 h-5 mr-2" />
                      NET
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <label htmlFor="quantity" className="block text-sm font-medium text-gray-700 mb-1">Quantity to Remove</label>
                <div className="relative">
                  <input
                    id="quantity"
                    type="text"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    placeholder={`0.0000 ${chainSymbol}`}
                    required
                  />
                  <DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                </div>
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="px-6 py-3 bg-gradient-to-r from-purple-500 to-indigo-600 text-white rounded-lg hover:from-purple-600 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex items-center disabled:opacity-50"
                  disabled={isProcessing}
                >
                  {isProcessing ? 'Processing...' : 'Remove CPU/NET'}
                </button>
              </div>
            </form>
          </div>
        </div>

        {notification && (
          <div className={`mt-4 p-4 rounded-md ${notification.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
            {notification.message}
          </div>
        )}
      </div>
    </div>
  );
};

export default RemoveCpuNet;