import React from 'react';
import { User } from 'lucide-react';
import { useChainContext } from '../contexts/ChainContext';

const AccountSummary = ({ accountInfo }) => {
  const { currentChain } = useChainContext();
  const chainConfig = {
    'wax-mainnet': { token: 'WAX' },
    'wax-testnet': { token: 'WAX' },
    'telos-mainnet': { token: 'TLOS' },
    'telos-testnet': { token: 'TLOS' },
    // Add more chain configurations as needed
  };

  const chainToken = chainConfig[currentChain]?.token || 'TOKEN';

  const formatNumber = (num) => {
    if (typeof num === 'string') {
      const [value, symbol] = num.split(' ');
      return `${parseFloat(value).toLocaleString(undefined, { maximumFractionDigits: 8 })} ${symbol || chainToken}`;
    }
    return parseFloat(num).toLocaleString(undefined, { maximumFractionDigits: 8 });
  };

  const availableBalance = parseFloat(accountInfo.core_liquid_balance?.split(' ')[0]) || 0;
  const refundAmount = parseFloat(accountInfo.refund_request?.net_amount?.split(' ')[0] || 0) + 
                       parseFloat(accountInfo.refund_request?.cpu_amount?.split(' ')[0] || 0);
  const cpuStaked = parseFloat(accountInfo.self_delegated_bandwidth?.cpu_weight?.split(' ')[0]) || 0;
  const netStaked = parseFloat(accountInfo.self_delegated_bandwidth?.net_weight?.split(' ')[0]) || 0;
  const rexVoteStake = currentChain.includes('telos')
    ? (parseFloat(accountInfo.rex_info?.vote_stake?.split(' ')[0]) || 0)
    : 0;

  const totalBalance = currentChain.includes('telos')
    ? availableBalance + refundAmount + cpuStaked + netStaked + rexVoteStake
    : availableBalance + refundAmount + cpuStaked + netStaked;

  return (
    <div className="bg-white rounded-lg shadow p-6 mb-6">
      <div className="flex items-center mb-4">
        <User className="w-8 h-8 mr-3 text-blue-500" />
        <h1 className="text-3xl font-bold">{accountInfo.account_name}</h1>
      </div>
      <div className="mt-4">
        <p className="text-xl font-semibold">Total Balance</p>
        <p className="text-4xl font-bold text-blue-600">{formatNumber(`${totalBalance.toFixed(8)} ${chainToken}`)}</p>
      </div>
      {currentChain.includes('telos') && rexVoteStake > 0 && (
        <div className="mt-2">
          <p className="text-sm text-gray-600">Includes REX Stake:</p>
          <p className="text-lg font-semibold">{formatNumber(`${rexVoteStake.toFixed(8)} ${chainToken}`)}</p>
        </div>
      )}
    </div>
  );
};

export default AccountSummary;