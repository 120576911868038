import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronDown, ChevronUp } from 'lucide-react';

function Sidebar({ isOpen, setSidebarOpen }) {
  const location = useLocation();
  const [walletOpen, setWalletOpen] = useState(false);

  const navItems = [
    { name: 'Monitor', path: '/' },
    { name: 'Account Detail', path: '/account-detail' },
    { 
      name: 'Wallet', 
      path: '/wallet',
      subItems: [
        { name: 'Transfer Tokens', path: '/wallet/transfer' },
        { name: 'Manage Resources (CPU/NET/RAM)', path: '/wallet/cpu-net-ram' },
        { name: 'Manage Keys', path: '/wallet/keys' },
        { name: 'Vote', path: '/wallet/vote' },
      ]
    },
    { name: 'Explorer', path: '/explorer' },
  ];

  return (
    <>
      <div
        className={`fixed inset-0 z-20 transition-opacity bg-black opacity-50 lg:hidden ${
          isOpen ? 'block' : 'hidden'
        }`}
        onClick={() => setSidebarOpen(false)}
      ></div>

      <div
        className={`fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto transition duration-300 transform bg-indigo-700 lg:translate-x-0 lg:static lg:inset-0 ${
          isOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'
        }`}
      >
        <nav className="mt-10">
          {navItems.map((item) => (
            <div key={item.name}>
              {item.subItems ? (
                <div>
                  <button
                    onClick={() => setWalletOpen(!walletOpen)}
                    className={`flex items-center justify-between w-full px-6 py-2 mt-4 text-gray-100 hover:bg-indigo-800 hover:bg-opacity-25 hover:text-gray-100`}
                  >
                    <span className="mx-3">{item.name}</span>
                    {walletOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                  </button>
                  {walletOpen && (
                    <div className="ml-6">
                      {item.subItems.map((subItem) => (
                        <Link
                          key={subItem.name}
                          className={`flex items-center px-6 py-2 mt-2 text-sm text-gray-100 ${
                            location.pathname === subItem.path
                              ? 'bg-indigo-800 bg-opacity-25 text-gray-100'
                              : 'hover:bg-indigo-800 hover:bg-opacity-25 hover:text-gray-100'
                          }`}
                          to={subItem.path}
                        >
                          <span className="mx-3">{subItem.name}</span>
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <Link
                  className={`flex items-center px-6 py-2 mt-4 text-gray-100 ${
                    location.pathname.startsWith(item.path)
                      ? 'bg-indigo-800 bg-opacity-25 text-gray-100'
                      : 'hover:bg-indigo-800 hover:bg-opacity-25 hover:text-gray-100'
                  }`}
                  to={item.path}
                >
                  <span className="mx-3">{item.name}</span>
                </Link>
              )}
            </div>
          ))}
        </nav>
      </div>
    </>
  );
}

export default Sidebar;