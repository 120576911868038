import React, { useEffect, useState, useCallback } from 'react';
import { useChainContext } from '../contexts/ChainContext';
import BlockchainService from '../services/blockchainService';

function BlockExplorer() {
  const { currentChain } = useChainContext();
  const [chainInfo, setChainInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchChainInfo = useCallback(async () => {
    console.log('BlockExplorer: Fetching chain info for', currentChain);
    setLoading(true);
    setError(null);
    try {
      const blockchain = new BlockchainService(currentChain);
      const info = await blockchain.getInfo();
      console.log('BlockExplorer: Chain info received', info);
      setChainInfo(info);
    } catch (err) {
      console.error('BlockExplorer: Error fetching chain info', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [currentChain]);

  useEffect(() => {
    fetchChainInfo();
  }, [fetchChainInfo]);

  const handleRefresh = () => {
    fetchChainInfo();
  };

  if (loading) return <div>Loading...</div>;
  if (error) return (
    <div>
      <h1>Error</h1>
      <p>{error}</p>
      <button onClick={handleRefresh}>Try Again</button>
    </div>
  );

  return (
    <div>
      <h1>Block Explorer: {currentChain}</h1>
      <button onClick={handleRefresh}>Refresh</button>
      {chainInfo && (
        <div>
          <p>Head Block: {chainInfo.head_block_num}</p>
          <p>Last Irreversible Block: {chainInfo.last_irreversible_block_num}</p>
          <p>Chain ID: {chainInfo.chain_id}</p>
          <p>Server Version: {chainInfo.server_version}</p>
        </div>
      )}
    </div>
  );
}

export default React.memo(BlockExplorer);