import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ChainProvider } from './contexts/ChainContext';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import Home from './pages/Home';
import BlockExplorer from './pages/BlockExplorer';
import Monitor from './pages/Monitor'; // Importa el nuevo componente
import TokenFetcher from './pages/TokenFetcher';
import AccountDetail from './pages/AccountDetail';
import TransferTokens from './pages/wallet/TransferTokens';
import Voters from './pages/wallet/Voters';
import { useEffect } from 'react';
import favicon from './assets/TELOS_favicon.png';
import Resources from './pages/wallet/Resources';

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    document.title = "ChainScout - Blockchain Explorer and Wallet";
    // Agregar el favicon
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = favicon;
    document.head.appendChild(link);

    // Limpieza al desmontar el componente
    return () => {
      document.head.removeChild(link);
    };
  }, []);


  return (
    <ChainProvider>
      <Router>
        <div className="flex h-screen bg-gray-100">
          <Sidebar isOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          
          <div className="flex-1 flex flex-col overflow-hidden">
            <Header setSidebarOpen={setSidebarOpen} />
            
            <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
              <div className="container mx-auto px-6 py-8">
                <Routes>
                  <Route path="/home" element={<Home />} /> {/* Nueva ruta */}                  
                  <Route path="/" element={<Monitor />} />
                  <Route path="/explorer" element={<BlockExplorer />} />
                  <Route path="/wallet/transfer" element={<TransferTokens />} />
                  <Route path="/token-fetcher" element={<TokenFetcher />} />
                  <Route path="/account-detail/:accountName" element={<AccountDetail />} />
                  <Route path="/wallet/vote" element={<Voters />} />
                  <Route path="/wallet/cpu-net-ram" element={<Resources />} />
                </Routes>
              </div>
            </main>
            
            <Footer />
          </div>
        </div>
      </Router>
    </ChainProvider>
  );
}

export default App;