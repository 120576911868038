import React, { useState, useEffect, useMemo } from 'react';
import { useChainContext } from '../../contexts/ChainContext';
import BlockchainService from '../../services/blockchainService';
import { Check, AlertCircle, ChevronUp, ChevronDown } from 'lucide-react';

function Voters() {
  const { currentChain, user, isAuthenticated } = useChainContext();
  const [producers, setProducers] = useState([]);
  const [selectedProducers, setSelectedProducers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isVoting, setIsVoting] = useState(false);
  const [notification, setNotification] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'owner', direction: 'asc' });
  const blockchainService = useMemo(() => new BlockchainService(currentChain), [currentChain]);

  useEffect(() => {
    const fetchProducers = async () => {
      setLoading(true);
      try {
        const response = await blockchainService.fetchFromChain('/get_table_rows', {
          scope: 'eosio',
          code: 'eosio',
          table: 'producers',
          json: true,
          limit: 1000
        });
        const activeProducers = response.rows
          .filter(producer => producer.is_active)
          .map(producer => ({
            ...producer,
            total_votes: parseFloat(producer.total_votes)
          }));
        setProducers(activeProducers);
        setError(null);
      } catch (err) {
        setError('Failed to fetch producers: ' + err.message);
        setProducers([]);
      } finally {
        setLoading(false);
      }
    };

    fetchProducers();
  }, [blockchainService]);

  const sortedProducers = useMemo(() => {
    let sortableProducers = [...producers];
    if (sortConfig.key) {
      sortableProducers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableProducers;
  }, [producers, sortConfig]);

  const handleSort = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const handleProducerSelection = (producer) => {
    setSelectedProducers(prevSelected => {
      if (prevSelected.includes(producer)) {
        return prevSelected.filter(p => p !== producer);
      } else if (prevSelected.length < 30) {
        return [...prevSelected, producer];
      }
      return prevSelected;
    });
  };

  const handleVote = async () => {
    if (!isAuthenticated) {
      setNotification({ message: 'Please login to vote', type: 'error' });
      return;
    }

    setIsVoting(true);
    try {
      const action = {
        account: 'eosio',
        name: 'voteproducer',
        authorization: [{ actor: user.name, permission: 'active' }],
        data: {
          voter: user.name,
          proxy: '',
          producers: selectedProducers.sort()
        }
      };

      const result = await user.session.signTransaction(
        { actions: [action] },
        { broadcast: true, blocksBehind: 3, expireSeconds: 30 }
      );

      setNotification({ message: 'Vote submitted successfully!', type: 'success' });
      console.log('Transaction ID:', result.transactionId);
    } catch (err) {
      setNotification({ message: 'Voting failed: ' + err.message, type: 'error' });
    } finally {
      setIsVoting(false);
    }
  };

  if (loading) return <div className="text-center p-4">Loading producers...</div>;
  if (error) return <div className="text-center p-4 text-red-500">Error: {error}</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      
      <h1 className="text-4xl font-extrabold mb-8 text-center text-indigo-800 leading-tight">Vote for Block Producers</h1>
      
      {notification && (
        <div className={`mb-4 p-4 rounded-md ${notification.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
          <div className="flex">
            <div className="flex-shrink-0">
              {notification.type === 'success' ? (
                <Check className="h-5 w-5 text-green-400" aria-hidden="true" />
              ) : (
                <AlertCircle className="h-5 w-5 text-red-400" aria-hidden="true" />
              )}
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium">{notification.message}</p>
            </div>
          </div>
        </div>
      )}
      <div className="mb-4 flex justify-between items-center">
        <p className="text-lg">
          Selected: <span className="font-bold text-indigo-600">{selectedProducers.length}/30</span>
        </p>
        <button
          onClick={handleVote}
          disabled={selectedProducers.length === 0 || isVoting || !isAuthenticated}
          className="px-4 py-2 bg-indigo-600 text-white rounded-md shadow hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 transition-colors duration-200"
        >
          {isVoting ? 'Submitting Vote...' : 'Submit Vote'}
        </button>
      </div>
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 p-4">
          {sortedProducers.map((producer) => (
            <div
              key={producer.owner}
              className={`p-4 border rounded-lg cursor-pointer hover:bg-gray-50 ${selectedProducers.includes(producer.owner) ? 'bg-indigo-50' : ''}`}
              onClick={() => handleProducerSelection(producer.owner)}
            >
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={selectedProducers.includes(producer.owner)}
                  onChange={() => {}}
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded mr-2"
                />
                <span className="text-sm font-medium text-gray-900">{producer.owner}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Voters;